<template>
  <div style="padding: 20px">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>
      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">筛选条件</div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent
            >
              <el-row :gutter="10">
                <el-col :lg="5" :md="14">
                  <el-form-item label-width="140px" label="单号/流水号">
                    <el-input
                      v-model="where.order_no"
                      placeholder="单号/流水号"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :lg="5" :md="14">
                  <el-form-item label-width="100px" label="收入/支出">
                    <el-select
                      clearable
                      class="ele-block"
                      v-model="where.cash_type"
                      placeholder="请选择"
                    >
                      <el-option label="收入" :value="1" />
                      <el-option label="支出" :value="2" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="5" :md="14">
                  <el-form-item label-width="100px" label="项目">
                    <!-- <div v-for="(item,key) in typelist">{{item}}{{key}}</div> -->
                    <el-select
                      clearable
                      class="ele-block"
                      v-model="where.items"
                      placeholder="请选择项目"
                    >
                      <el-option v-for="(item,key) in typelist" :label="item" :value="key" />
                      <!-- <el-option label="支出" :value="1" /> -->
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="5" :md="12">
                  <el-form-item label-width="100px" label="详情">
                    <el-input
                      v-model="where.details"
                      placeholder="请输入"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12" style="text-align: center">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList"
                      >查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">数据列表</div>
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :customsFromWhere="where"
              :loading="loading"
               :total="total"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >
              <template v-slot:withdraw_time="{ scope }">
                <span>{{ scope.row.withdraw_time | formatDate }}</span>
              </template>
              <template v-slot:cash_type="{ scope }">
                <span v-if="scope.row.cash_type == 1">收入</span>
                <span v-else>支出</span>
              </template>
            </my-table>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
// 引入自定义表格组件
import MyTable from "@/components/MyTables";
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
import {incomelist, incomeitems} from "@/api/finance";
// 权限
import { ShowTable } from "@/utils";

export default {
  name: "index",
  // 支柱道具属性
  props: {},
  // 组件生效
  components: {
    MyTable,
    PackageSortElement,
  },
  // Data数据
  data() {
    return {
      // 表格搜索条件
      typelist:[],
      domain: "",
      where: {
        page: 1, // 当前页数
        page_size: 10, // 每页显示条目个数
        order_no: "",
        cash_type: "",
        details:''
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading: true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true,
        },
        {
          label: "单号",
          prop: "order_no",
          width: "300",
          isShow: true,
        },
        {
          label: "流水号",
          prop: "trade_no",
          isShow: true,
        },
        {
          label: "收入/支出",
          prop: "cash_type",
          isShow: true,
          slot: "cash_type",
        },
        {
          label: "项目",
          prop: "project_type",
          isShow: true,
        },
        {
          label: "详情",
          prop: "details",
          isShow: true,
        },
        {
          label: "金额",
          prop: "pay_price",
          isShow: true,
        },
        {
          label: "手续费",
          width: '100',
          prop: "fee",
          isShow: true,
        },
        {
          label: "交易时间",
          width: "200",
          prop: "pay_time",
          // slot: "operationSlot",
          isShow: true,
        },
      ],

      // 列表设置弹窗
      ListSetupShow: false,
      in_hand: 0,
    };
  },
  filters: {
    formatDate(value, format) {
      if (!value) return "";
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  activated() {
    this.getList();
  },
  // 方法集合
  methods: {
    getList() {
      incomelist(this.where).then((res) => {
        this.List = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
      incomeitems().then((res) => {
         if(res.code==200){
           this.typelist=res.data;
         }
      });
    },
    // 查询条件
    QueryList() {
      this.where.page = 1;
      this.where.page_size = 10;
      this.getList();
    },
    // 清空
    empty() {
      this.where = {
        page: 1,
        page_size: 10
      }
      this.getList();
    },
    // 当前页数改变事件
    currentChange(val) {
      // console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      // console.log(selection)
      // console.log("选择的所有数据行", selection)
      // console.log("当前选择的数据行", row)
    },
    ChangeSize(val) {
      // console.log('当前页面显示的条数',val);
      this.where.page_size = val;
      this.getList();
    },
    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA";
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 点击列表设置
    ListSetup() {
      this.ListSetupShow = true;
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 20px;
}

.condition {
  .condition_t {
    display: flex;
    align-items: center;
  }
  .condition_t_icon {
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .condition_t_text {
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}
.flex {
  display: flex;
  align-items: center;
}
.w-50 {
  width: 400px;
}
.youbianjudingshirenwu {
  margin-left: 15px;
}
.zuobianjudingshirenwu {
  margin-right: 15px;
}
</style>
